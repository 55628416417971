import { FC, useContext } from "react";
import { Col, Row } from "../../grid";
import { ProgressBar } from "../../progress/ProgressBar";
import { Text } from "../../typography/Text";
import { AppContext } from "../../../App";

export type SkillEntryType = {
  name: string;
  since: number;
};

export const SkillsList: FC<{ list: SkillEntryType[] }> = ({ list }) => {
  const { keys } = useContext(AppContext);
  return (
    <div style={{ marginBottom: "5rem" }}>
      <Row gutter={["large", "large"]}>
        {list.map((entry) => (
          <Col md={12} key={entry.name}>
            <ProgressBar
              percent={
                ((new Date().getFullYear() - entry.since) /
                  (new Date().getFullYear() -
                    new Date(keys?.["experienceStartDate"]).getFullYear())) *
                100
              }
            >
              <Text>{entry.name}</Text>&nbsp;&nbsp;
              <Text opacity={50}>
                ({new Date().getFullYear() - entry.since} years)
              </Text>
            </ProgressBar>
          </Col>
        ))}
      </Row>
    </div>
  );
};
