import { FC, useEffect, useState } from "react";
import { Section } from "../components/layout/Section";
import { fetchProjects } from "../data/data";
import { Col, Row } from "../components/grid";
import { Text } from "../components/typography/Text";
import dayjs from "dayjs";
import { Paragraph } from "../components/typography/Paragraph";
import { Badge } from "../components/notify/Badge";

type ProjectEntry = {
  title: string;
  client: string;
  from: Date;
  to: Date;
  technologies: string[];
  description: string;
};

export const Projects: FC = () => {
  const [list, setList] = useState<ProjectEntry[] | null>();
  useEffect(() => {
    fetchProjects().then((response) => {
      setList(
        response.reverse().map((row) => ({
          title: row[0],
          client: row[1],
          from: new Date(row[2]),
          to: new Date(row[3]),
          technologies: row[4]?.split(","),
          description: row[5],
        }))
      );
    });
  }, []);
  return (
    <Section title="Projects" busy={!list}>
      <Row gutter={["large", "large"]}>
        {list?.map((entry, idx) => (
          <Col key={idx} span={24}>
            <Row>
              <Col sm={10}>
                <Text weight="bold">{entry.title}</Text>
                <br />
                <Text>{entry.client}</Text>
                <br />
                <Text opacity={40}>
                  {dayjs(entry.from).format("MM/YYYY")} -{" "}
                  {entry.to ? dayjs(entry.to).format("MM/YYYY") : "now"}
                </Text>
              </Col>
              <Col sm={14}>
                <Paragraph>
                  <Text opacity={60}>{entry.description}</Text>
                </Paragraph>
                <Row gutter={["small", "small"]}>
                  {entry.technologies?.map((name) => (
                    <Col key={name}>
                      <Badge>{name}</Badge>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </Section>
  );
};
