import { FC, useEffect, useState, useContext } from "react";
import { Section } from "../components/layout/Section";
import { fetchClients } from "../data/data";
import { Col, Row } from "../components/grid";
import { SVGLogo } from "../components/media/SVGLogo";
import { AppContext } from "../App";

type ClientEntry = {
  name: string;
  link: string;
  logo: string;
};

export const Clients: FC = () => {
  const { isMobile } = useContext(AppContext);
  const [list, setList] = useState<ClientEntry[]>();
  useEffect(() => {
    fetchClients().then((response) => {
      setList(
        response.map((row) => ({
          name: row[0],
          link: row[1],
          logo: row[2],
        }))
      );
    });
  }, []);

  return (
    <Section title="Clients" busy={!list}>
      <Row gutter={isMobile ? ["regular", "regular"] : ["large", "large"]}>
        {list?.map((entry) => (
          <Col key={entry.name} sm={6} xs={8}>
            <SVGLogo data={entry.logo} url={entry.link} />
          </Col>
        ))}
      </Row>
    </Section>
  );
};
