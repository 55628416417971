import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "../components/grid";
import { Section } from "../components/layout/Section";
import { Image } from "../components/media/Image";
import { Paragraph } from "../components/typography/Paragraph";
import { fetchAbout } from "../data/data";
import { AppContext } from "../App";

export const About: FC = () => {
  const [data, setData] = useState<string[] | null>(null);
  const { isMobile, keys } = useContext(AppContext);
  useEffect(() => {
    fetchAbout().then(setData);
  }, []);

  return (
    <Section title="About" busy={!data}>
      <Row gutter={isMobile ? ["regular", "regular"] : ["large", "large"]}>
        <Col xs={7} sm={6} md={5} align={["right", "top"]}>
          <Image grey src={keys?.profileImageUrl} />
        </Col>
        <Col xs={17} sm={18} md={18}>
          {data?.map((entry: string, idx: number) => (
            <Paragraph key={idx}>{entry}</Paragraph>
          ))}
        </Col>
      </Row>
    </Section>
  );
};
