import React, { FC, ReactNode } from "react";
import styles from "./Paragraph.module.scss";
import classNames from "classnames";

export const Paragraph: FC<{
  children?: ReactNode;
  weight?: string;
  block?: boolean;
}> = ({ children, weight }) => {
  return React.createElement(
    "p",
    {
      className: classNames(styles.paragraph, {
        [styles.weightBold]: weight === "bold",
      }),
    },
    children
  );
};
