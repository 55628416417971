import queryString from "query-string";

const SPREADSHEET_ID = "1MRUabBQFuI1Wi6iszaz3N6KGpkz8PGT_RKUb4XhnIdA";

const apiUrl = new URL(
  `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values`
);
apiUrl.searchParams.set("alt", "json");
apiUrl.searchParams.set("key", "AIzaSyA9vYZydzHEwE4VN884-4KdQtS0AzO4baY");

const headers: { Accept: string; "Content-Type"?: string } = {
  Accept: "application/json",
};

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

function call<Response>(
  method: HttpMethod,
  url: string,
  payload?: object
): Promise<Response> {
  if (payload) {
    headers["Content-Type"] =
      "application/x-www-form-urlencoded; charset=UTF-8";
  }

  const callingUrl = new URL(apiUrl);
  callingUrl.pathname = callingUrl.pathname + url;
  return fetch(callingUrl, {
    method,
    headers,
    credentials: "same-origin",
    body: payload ? queryString.stringify(payload) : null,
  }).then(async (response) => {
    const contentType = response.headers.get("content-type");
    if (response.status >= 200 && response.status < 300) {
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const jsonData = await response.json();
        return jsonData?.["values"];
      }
      return response.text();
    }
    return Promise.reject(response);
  });
}

export function postRequest<Response>(url: string, payload: object) {
  return call<Response>("POST", url, payload);
}

export function putRequest<Response>(url: string, payload: object) {
  return call<Response>("PUT", url, payload);
}

export function deleteRequest<Response>(url: string) {
  return call<Response>("DELETE", url);
}

export function getRequest<Response>(url: string) {
  return call<Response>("GET", url);
}
