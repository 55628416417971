import { FC, ReactNode, useEffect, useState, createRef } from "react";
import styles from "./ProgressBar.module.scss";
import classNames from "classnames";

export const ProgressBar: FC<{
  children?: ReactNode;
  percent?: number;
}> = ({ children, percent }) => {
  const baseRef = createRef<HTMLDivElement>();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    var watchMe = new IntersectionObserver(onEntry, { threshold: [0.5] });
    if (baseRef.current) {
      watchMe.observe(baseRef?.current);
    }
  }, [baseRef]);

  const onEntry = (entry: IntersectionObserverEntry[]) => {
    entry.forEach((change) => {
      if (change.isIntersecting) {
        setVisible(true);
      }
    });
  };

  return (
    <div
      ref={baseRef}
      className={classNames(styles.base, { [styles.visible]: visible })}
    >
      <div className={styles.label}>{children}</div>
      <div className={styles.trail}>
        <div
          className={styles.bar}
          style={{ width: `${visible ? percent : 0}%` }}
        />
      </div>
    </div>
  );
};
