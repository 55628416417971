import React, { FC, useEffect, useState } from "react";
import { Section } from "../components/layout/Section";
import { SkillEntryType, SkillsList } from "../components/list/SkillsList";
import { fetchExperience } from "../data/data";

export const Experience: FC = () => {
  const [experiences, setExperiences] = useState<
    { header: string; list: SkillEntryType[] }[]
  >([]);
  useEffect(() => {
    const exp: { header: string; list: SkillEntryType[] }[] = [];
    fetchExperience().then((response: string[][]) => {
      response?.map((row, idx) => {
        if (idx === 0) {
          row.map((value) => {
            if (value !== "") {
              exp[exp.length] = {
                header: value,
                list: [],
              };
            }
          });
        } else {
          exp.map((obj, idx) => {
            const name = row[0 + idx * 2];
            const since = row[1 + idx * 2];
            if (name && since) {
              obj.list.push({
                name: row[0 + idx * 2],
                since: Number(since),
              });
            }
          });
        }
      });
      setExperiences(exp);
    });
  }, []);
  return (
    <React.Fragment>
      {experiences.map((section) => (
        <Section key={section.header} title={section.header}>
          <SkillsList list={section.list} />
        </Section>
      ))}
    </React.Fragment>
  );
};
