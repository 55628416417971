import React, { FC, ReactNode } from "react";
import styles from "./Section.module.scss";
import { Loader } from "../../progress/Loader";

export const Section: FC<{
  title?: string;
  subtitle?: string;
  busy?: boolean;
  children?: ReactNode;
}> = ({ title, subtitle, children, busy }) => {
  return (
    <section className={styles.section}>
      <div className={styles.title}>
        <h2>
          {title}{" "}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </h2>
      </div>
      <div className={styles.content}>{busy ? <Loader /> : children}</div>
    </section>
  );
};
