import { FC } from "react";
import styles from "./SVGLogo.module.scss";

export const SVGLogo: FC<{
  data?: string;
  url: string;
}> = ({ data, url }) => {
  return data && url ? (
    <a
      href={url}
      target="blank"
      className={styles.svg_logo}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  ) : null;
};
