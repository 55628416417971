import { FC, useEffect, useState } from "react";
import { Col, Row } from "../components/grid";
import { Section } from "../components/layout/Section";
import { Point, PointList } from "../components/list/PointList";
import { Paragraph } from "../components/typography/Paragraph";
import { Text } from "../components/typography/Text";
import { fetchEducation } from "../data/data";

type EducationEntry = {
  from: number;
  to: number;
  school: string;
  degree: string;
};

export const Education: FC = () => {
  const [list, setList] = useState<EducationEntry[] | null>();
  useEffect(() => {
    fetchEducation().then((response) => {
      setList(
        response.reverse().map((row) => {
          return {
            from: Number(row[0]),
            to: Number(row[1]),
            school: row[2],
            degree: row[3],
          };
        })
      );
    });
  }, []);
  return (
    <Section title="Education" busy={!list}>
      <PointList>
        {list?.map((entry, idx) => (
          <Point key={idx}>
            <Row gutter={["small", "small"]}>
              <Col span={10}>
                {entry.from} - {entry.to}
              </Col>
              <Col span={14}>
                <Text weight="bold">{entry.school}</Text>
                <br />
                <Text>{entry.degree}</Text>
              </Col>
            </Row>
          </Point>
        ))}
      </PointList>
    </Section>
  );
};
