import dayjs from "dayjs";
import { FC, useState, useEffect } from "react";
import { Col, Row } from "../components/grid";
import { Section } from "../components/layout/Section";
import { Point, PointList } from "../components/list/PointList";
import { Paragraph } from "../components/typography/Paragraph";
import { Text } from "../components/typography/Text";
import { fetchWork } from "../data/data";

type WorkEntry = {
  from: Date;
  to?: Date;
  company: string;
  role: string;
  description?: string;
};

export const Work: FC = () => {
  const [list, setList] = useState<WorkEntry[] | null>();
  useEffect(() => {
    fetchWork().then((response) => {
      setList(
        response.reverse().map((row) => {
          return {
            from: new Date(row[0]),
            to: row[1] ? new Date(row[1]) : undefined,
            company: row[2],
            role: row[3],
            description: row[4],
          };
        })
      );
    });
  }, []);
  return (
    <Section title="Work experience" busy={!list}>
      <PointList>
        {list?.map((entry, idx) => (
          <Point key={idx}>
            <Row gutter={["regular", "none"]}>
              <Col sm={10}>
                <Text>
                  {dayjs(entry.from).format("MM/YYYY")} -{" "}
                  {entry.to ? dayjs(entry.to).format("MM/YYYY") : "current"}
                </Text>
              </Col>
              <Col sm={14}>
                <Paragraph>
                  <Text weight="bold">{entry.company}</Text>
                  <br />
                  <Text>{entry.role}</Text>
                </Paragraph>
                <Text opacity={60}>{entry.description}</Text>
              </Col>
            </Row>
          </Point>
        ))}
      </PointList>
    </Section>
  );
};
