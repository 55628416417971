import classNames from "classnames";
import { FC, ReactNode, useContext } from "react";
import { AppContext } from "../../../App";
import styles from "./Heading.module.scss";

export const Heading: FC<{
  children?: ReactNode;
}> = () => {
  const { scrollY, screenHeight, isMobile } = useContext(AppContext);
  return (
    <div
      className={classNames(styles.heading, {
        [styles.continue]: !!scrollY && scrollY >= 100,
      })}
      style={isMobile ? { height: `${screenHeight}px` } : undefined}
    >
      <div className={styles.title}>
        <h1>Eivind Eriksen</h1>
      </div>
      <div className={styles.arrow}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1920">
          <path
            fillRule="evenodd"
            d="M902.83 0v1699.925l-476.465-476.467L345 1304.823 960.177 1920l615.407-615.177-81.365-81.365-476.466 476.467V0z"
          />
        </svg>
      </div>
    </div>
  );
};
