import { FC } from "react";
import styles from "./Image.module.scss";
import classNames from "classnames";

export const Image: FC<{
  src?: string;
  alt?: string;
  circle?: boolean;
  grey?: boolean;
}> = ({ src, alt, circle, grey }) => {
  return (
    <img
      alt={alt || "Image"}
      className={classNames(styles.image, {
        [styles.circle]: circle,
        [styles.grey]: grey,
      })}
      src={src}
    />
  );
};
