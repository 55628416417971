import { getRequest } from "./fetch";

export const fetchKeys = async () => {
  return await getRequest<string[]>(`/keys`);
};

export const fetchAbout = async () => {
  return await getRequest<string[]>(`/about`);
};

export const fetchExperience = async () => {
  return await getRequest<string[][]>(`/experience`);
};

export const fetchWork = async () => {
  return await getRequest<string[][]>(`/work`);
};

export const fetchEducation = async () => {
  return await getRequest<string[][]>(`/education`);
};

export const fetchProjects = async () => {
  return await getRequest<string[][]>(`/projects`);
};

export const fetchClients = async () => {
  return await getRequest<string[][]>(`/clients`);
};

export const fetchContact = async () => {
  return await getRequest<string[][]>(`/contact`);
};
