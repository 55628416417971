import { createContext, useEffect, useState } from "react";
import { Content } from "./components/layout/Content";
import { Heading } from "./components/layout/Heading";
import { Layout } from "./components/layout/Layout";
import { fetchKeys } from "./data/data";
import { About } from "./pages/About";
import { Clients } from "./pages/Clients";
import { Contact } from "./pages/Contact";
import { Education } from "./pages/Education";
import { Experience } from "./pages/Experience";
import { Projects } from "./pages/Projects";
import { Work } from "./pages/Work";

export const AppContext = createContext<{
  keys: any | null;
  isMobile: boolean;
  scrollY: number | null;
  screenHeight: number | null;
}>({
  keys: null,
  isMobile: false,
  scrollY: null,
  screenHeight: null,
});

export const App = () => {
  const [keys, setKeys] = useState<any | null>({});
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number | null>(null);
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    handleResize();
    fetchKeys().then((response) => {
      setKeys(
        response.reduce((agg, row) => {
          agg = {
            ...agg,
            [row[0]]: row[1],
          };
          return agg;
        }, {})
      );
    });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize, false);
      window.addEventListener("scroll", handleScroll, false);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize, false);
        window.removeEventListener("scroll", handleScroll, false);
      }
    };
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
    setScreenHeight(window.innerHeight);
  };

  const handleScroll: EventListener = (event: Event) => {
    setScrollY(window.scrollY);
  };

  return (
    <AppContext.Provider value={{ keys, isMobile, scrollY, screenHeight }}>
      <Layout>
        <Heading />
        <Content>
          <About />
          <Experience />
          <Work />
          <Education />
          <Projects />
          <Clients />
          <Contact />
        </Content>
      </Layout>
    </AppContext.Provider>
  );
};
