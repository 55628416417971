import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./Col.module.scss";

export const Col: FC<{
  span?: number;
  order?: number;
  offset?: number;
  push?: number;
  pull?: number;
  gutter?: Array<string>;
  align?: Array<string>;
  grow?: boolean;
  shrink?: boolean;
  compact?: boolean;
  sticky?: boolean;
  children?: ReactNode;
  xs?: object | number;
  sm?: object | number;
  md?: object | number;
  lg?: object | number;
}> = (props) => {
  const {
    span,
    order,
    offset,
    push,
    pull,
    gutter,
    align,
    grow,
    shrink,
    compact,
    sticky,
    children,
  } = props;
  let spanLabel: number = 0;
  let sizeClassObj = {};

  const sizes: string[] = ["xs", "sm", "md", "lg"];
  sizes.forEach((size: string) => {
    let sizeProps: any = {
      span,
      order,
      offset,
      push,
      pull,
      sticky,
    };

    let sizeKey = size as keyof typeof props;
    if (typeof props[sizeKey] === "number") {
      sizeProps.span = props[sizeKey];
    } else if (typeof props[sizeKey] === "object") {
      sizeProps = props[sizeKey] || {};
    }

    // Set span label
    if (sizeProps?.span && sizeProps.span > spanLabel) {
      spanLabel = sizeProps.span;
    }

    sizeClassObj = Object.assign({}, sizeClassObj, {
      [styles[`col_${size}_${sizeProps.span}`]]: sizeProps.span !== undefined,
      [styles[`col_${size}_order_${sizeProps.order}`]]:
        sizeProps.order || sizeProps.order === 0,
      [styles[`col_${size}_offset_${sizeProps.offset}`]]:
        sizeProps.offset || sizeProps.offset === 0,
      [styles[`col_${size}_push_${sizeProps.push}`]]:
        sizeProps.push || sizeProps.push === 0,
      [styles[`col_${size}_pull_${sizeProps.pull}`]]:
        sizeProps.pull || sizeProps.pull === 0,
      [styles[`col_${size}_sticky`]]: sizeProps.sticky,
    });
  });

  const colClasses = classNames(
    {
      [styles[`col_${span}`]]: span !== undefined,
      [styles[`col_order_${order}`]]: order,
      [styles[`col_offset_${offset}`]]: offset,
      [styles[`col_push_${push}`]]: push,
      [styles[`col_pull_${pull}`]]: pull,
      [styles[`gutter_ver_${gutter?.[0]}`]]: gutter?.[0],
      [styles[`gutter_hor_${gutter?.[1]}`]]: gutter?.[1],
      [styles[`align_hor_${align?.[0]}`]]: align?.length && align[0],
      [styles[`align_ver_${align?.[1]}`]]: align?.length && align[1],
      [styles.grow]: grow,
      [styles.noShrink]: !shrink,
      [styles.compact]: compact,
    },
    sizeClassObj
  );

  return <div className={colClasses}>{children}</div>;
};

Col.defaultProps = {
  shrink: true,
  align: [],
};
