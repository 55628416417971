import classNames from "classnames";
import PropTypes from "prop-types";
import React, { FC, ReactElement, ReactNode } from "react";
import { Col } from "../Col";
import styles from "./Row.module.scss";

export const Row: FC<{
  children?: ReactNode;
  gutter?: Array<string>;
  spacing?: string;
}> = ({ children, gutter }) => {
  return (
    <div
      className={classNames(styles.row, {
        [styles[`gutter_ver_${gutter?.[0]}`]]: gutter?.[0],
        [styles[`gutter_hor_${gutter?.[1]}`]]: gutter?.[1],
      })}
    >
      {children &&
        React.Children.map(children, (child: ReactNode) => {
          return React.isValidElement(child) && child.type === Col
            ? React.cloneElement(child as ReactElement<any>, {
                gutter,
              })
            : child;
        })}
    </div>
  );
};

Row.defaultProps = {
  gutter: ["regular", "regular"],
};

Row.propTypes = {
  gutter: PropTypes.array,
};
