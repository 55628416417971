import { FC, useEffect, useState } from "react";
import { Section } from "../components/layout/Section";
import { Col, Row } from "../components/grid";
import { Text } from "../components/typography/Text";
import { fetchContact } from "../data/data";
import { ContactButton } from "../components/button/ContactButton";

type ContactEntry = {
  label: string;
  text: string;
  link: string;
};

export const Contact: FC = () => {
  const [list, setList] = useState<ContactEntry[]>();
  useEffect(() => {
    fetchContact().then((response) => {
      setList(
        response.map((row) => ({
          label: row[0],
          text: row[1],
          link: row[2],
        }))
      );
    });
  }, []);

  const handleClickEntry = (entry: ContactEntry) => {
    window.open(entry.link);
  };

  return (
    <Section title="Contact" busy={!list}>
      <Row>
        {list?.map((entry, idx) => (
          <Col key={idx} span={24}>
            <Text opacity={50}>{entry.label}</Text>
            <br />
            <Text
              weight="bold"
              onClick={entry.link ? () => handleClickEntry(entry) : undefined}
            >
              {entry.text}
            </Text>
          </Col>
        ))}
      </Row>
      {/* <ContactButton
        emailLink={list?.find((e) => e.link.startsWith("mailto"))?.link}
      /> */}
    </Section>
  );
};
