import React, { FC, ReactNode } from "react";
import styles from "./Text.module.scss";
import classNames from "classnames";

export const Text: FC<{
  children?: ReactNode;
  weight?: string;
  opacity?: number;
  onClick?: () => void;
}> = ({ children, weight, opacity, onClick }) => {
  return React.createElement(
    "span",
    {
      onClick,
      className: classNames(styles.text, {
        [styles.weightBold]: weight === "bold",
        [styles.link]: !!onClick,
      }),
      style: {
        ...(opacity && { opacity: opacity / 100 }),
      },
    },
    children
  );
};
